import firebase from "firebase/app";
import "firebase/database";
import 'firebase/storage';
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCYbRrVmfXJQvAqnYwXtnEUR51Rh1VgIxo",
  authDomain: "mayfair-tours.firebaseapp.com",
  databaseURL: "https://mayfair-tours-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mayfair-tours",
  storageBucket: "mayfair-tours.appspot.com",
  messagingSenderId: "183738771427",
  appId: "1:183738771427:web:3be74399ab7c3c6835a2d6"
};

const firebaseInit = firebase.initializeApp(firebaseConfig);
const fireDb = firebaseInit.database().ref();
const storage = firebase.storage();
const auth = firebase.auth();

const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (first_name, last_name, email, password, company_name) => {
  try {
    const res = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const user = res.user;
    
    const user_id = fireDb.child("user_profile").push().getKey();    
    fireDb.child("user_profile").child(user_id).set({
      uid: user.uid,
      first_name : first_name,
      last_name : last_name,
      company_name : company_name,
    });
    return res;
  } catch (err) {
    alert(err.message);
  }
};

export  {
  storage,
  auth, 
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  fireDb as default
}