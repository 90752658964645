import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import "./Register.css";
import { auth, storage, logInWithEmailAndPassword, registerWithEmailAndPassword } from "../firebase";
import { toast } from "react-toastify";

const initialState = {
  first_name:"",
  last_name:"",
  email: "",
  password: "",
  company: ""
};

const Register = () => {
  const [state, setState] = useState(initialState);
  const { first_name, last_name, email, password, company } = state;

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if(auth.currentUser !== null){
      history.push("/home");
    }
  }, [auth]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!first_name && !email && !password) {
      toast.error("Please enter first name, email and password!");
    } else {
      const registerResp = await registerWithEmailAndPassword(first_name, last_name, email, password, company);
      console.log("registerResp", registerResp);
      if(registerResp !== undefined){
        history.push("/home");
      }      
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <form
        style={{
          margin: "auto",
          padding: "30px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit}
      >
        <div className = "row">
            <div className = "col-md-12">

              <div className = "col-md-4 offset-md-4">

                <label htmlFor="first_name">First name</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Your First Name..."
                  value={first_name || ""}
                  onChange={handleInputChange}
                /> 
              
                <label htmlFor="last_name">Last name</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Your Last Name..."
                  value={last_name || ""}
                  onChange={handleInputChange}
                />              
                
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email..."
                  value={email || ""}
                  onChange={handleInputChange}
                />  

                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Your Password..."
                  value={password || ""}
                  onChange={handleInputChange}
                />                            

                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  placeholder="Your Company..."
                  value={company || ""}
                  onChange={handleInputChange}
                /> 

                <p>Already have an account? Please <Link to="/login">Login Here</Link></p>
                <input type="submit" value="Register" />

            </div>
          </div>
        
        </div>

      </form>
    </div>
  );
};

export default Register;